import React, { useEffect } from 'react'
import { Router } from '@reach/router' // eslint-disable-line import/no-unresolved
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import clsx from 'clsx'
import Seo from '../../components/Seo'
import Layout from '../../components/Layout'
import LoginForm from '../../components/LoginForm'
import Loader from '../../components/Loader'
import NotInAcademyModal from '../../components/NotInAcademyModal'
import Dashboard from '../../components/Dashboard'
import Courses from '../../components/Courses'
import Challenges from '../../components/Challenges'
import SugarDough from '../../components/SugarDough'
import Art from '../../components/Art'
import Business from '../../components/Business'
import Race from '../../components/Race'
import i18n from '../../locales/he.yaml'
import useAuth from '../../hooks/useAuth'
import { coursesImagesMapping } from '../../utils'

const DashboardRoot = ({ data, location: { pathname } }) => {
  const { user, isLoaded, authenticate } = useAuth()
  const coursesImages = coursesImagesMapping(data)

  useEffect(() => {
    if (!isLoaded) {
      authenticate()
    }
  }, [])

  return (
    <Layout isPrivatePage>
      <Seo
        metadata={i18n.dashboard.metadata}
        pathname={pathname.replace('[...path]/', '')}
        og={{
          image: getSrc(data.dashboardHero),
          alt: i18n.courses.mainImage.alt
        }}
        robots='noindex, nofollow'
      />
      {!user ? (
        <>
          <div
            className={clsx('hero', {
              'is-invisible': isLoaded,
              'has-navbar-fixed-top': !isLoaded
            })}
            style={{ height: !isLoaded ? 'auto' : 0 }}
          >
            <div className='hero-body'>
              <div className='container'>
                <Loader />
              </div>
            </div>
          </div>
          <div
            className={clsx('hero', {
              'is-invisible': !isLoaded,
              'has-navbar-fixed-top': isLoaded
            })}
            style={{ height: !isLoaded ? 0 : 'auto' }}
          >
            <div className='hero-body'>
              <div className='container'>
                <div className='columns is-centered'>
                  <div className='column is-6-desktop is-9-tablet is-12-mobile'>
                    <LoginForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Router basepath='/dashboard'>
          <Dashboard path='/' NotInAcademyModal={NotInAcademyModal} />
          <Courses path='/courses' images={coursesImages} />
          <Challenges
            path='/challenges'
            NotInAcademyModal={NotInAcademyModal}
            images={coursesImages}
          />
          <SugarDough
            path='/sugar-dough'
            NotInAcademyModal={NotInAcademyModal}
          />
          <Art path='/art' NotInAcademyModal={NotInAcademyModal} />
          <Business path='/business' NotInAcademyModal={NotInAcademyModal} />
          <Race path='/race' />
        </Router>
      )}
    </Layout>
  )
}

export default DashboardRoot

export const query = graphql`
  query MyCoursesQuery {
    dashboardHero: file(relativePath: { eq: "logo-academy-circle.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    sugarDoughBasics: file(
      relativePath: { eq: "יסודות-לעבודה-עם-בצק-סוכר.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    baseCakeBasics: file(relativePath: { eq: "יסודות-ליצירת-עוגת-הבסיס.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    cakeDesignEquipment: file(
      relativePath: { eq: "הציוד-ההכרחי-לעיצוב-עוגה.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    sculptingMethod: file(relativePath: { eq: "אמנות-הפיסול-ב-5-שלבים.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bear: file(
      relativePath: {
        eq: "עוגה-מעוצבת-עם-פיסול-דובי-בחצי-תלת-מימד-בבצק-סוכר-ובלונים-מג׳לטין.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    rabbit: file(
      relativePath: { eq: "עוגה-מעוצבת-עם-פיסול-ארנב-בתלת-מימד-בבצק-סוכר.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    candies: file(
      relativePath: {
        eq: "עוגת-ממתקים-מעוצבת-בבצק-סוכר-עם-בובה-וחתול-מפוסלים-בעבודת-יד.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tallButterflies: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מצופה-בטקסטורת-שוקולד-עם-פסלים-של-טירה-מפוסלים-מבצק-סוכר-ופרפרים-מנייר-אורז.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    airplanes: file(
      relativePath: {
        eq: "עוגה-מעוצבת-בסגנון-וינטג׳-עם-ציור-ופיסול-פנים-של-ילד-מפוסל-בצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    giftBox: file(
      relativePath: { eq: "עוגה-מעוצבת-בבצק-סוכר-בצורה-של-קופסת-מתנה.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tiersCastle: file(
      relativePath: {
        eq: "עוגת-2-קומות-מעוצבת-בצורת-טירה-מפוסלת-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tallSafari: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מקושטת-בדפי-אורז-ועם-חיות-הספארי-אריה-ג׳ירפה-קוף-פיל-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tallCircus: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מקושטת-בדפי-אורז-ועם-חיות-הקרקס-קוף-פיל-נמר-כלב-ים-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    princess: file(
      relativePath: { eq: "נסיכה-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    ballerina: file(
      relativePath: { eq: "בלרינה-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    llama: file(
      relativePath: {
        eq: "לאמה-עומדת-בעלת-מבנה-של-4-רגליים-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    safariAnimalsPro: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-חיות-הספארי-אריה-ג׳ירפה-קוף-פיל-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    circusAnimalsPro: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מקושטת-בדפי-אורז-ועם-חיות-הקרקס-קוף-פיל-נמר-כלב-ים-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    safariAnimalsMasters: file(
      relativePath: {
        eq: "חיות-הספארי-אריה-ג׳ירפה-זברה-היפופוטם-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tractor: file(
      relativePath: {
        eq: "עוגת-טרקטור-תלת-מימדי-מפוסל-בבצק-סוכר-עם-שלט-מזל-טוב.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    jeepSafariAnimals: file(
      relativePath: {
        eq: "עוגת-ג׳יפ-של-חיות-הספארי-בתלת-מימד-אריה-ג׳ירפה-זברה-היפופוטם-מפוסלים-בבצק-סוכר-ועצים-מנייר-אורז.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    tallPatches: file(
      relativePath: {
        eq: "עוגה-מעוצבת-סופר-גבוהה-מקושטת-בטלאים-ושמיכה-ממרשמלו-ועליה-בובות-ענקיות-של-כלב-עשוי-מכדורי-שוקולד-וארנב-מפוסל-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    volcano: file(
      relativePath: {
        eq: "עוגת-הר-געש-תלת-מימד-עם-עשן-ועננים-ועליה-דינוזאורים-טי-רקס-טריצרטופס-דיפלודוקוס-מפוסלים-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    dinosaurs: file(relativePath: { eq: "דינוזאורים-מפוסלים-בבצק-סוכר.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    calimero: file(
      relativePath: {
        eq: "עוגות-עם-אפרוחים-קלימרו-ופריסילה-מעוצבים-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    zebra: file(relativePath: { eq: "זברה-של-ספארי-מפוסלת-בבצק-סוכר.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    dino: file(relativePath: { eq: "דינו-מפוסל-בבצק-סוכר.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    dog: file(
      relativePath: { eq: "בובה-ענקית-של-כלב-עשוי-מכדורי-שוקולד.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bigRabbit: file(
      relativePath: { eq: "בובה-ענקית-של-ארנב-מפוסל-בבצק-סוכר.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    stork: file(relativePath: { eq: "חסידה-מפוסלת-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    clown: file(relativePath: { eq: "ליצנית-מפוסלת-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    dwarf: file(relativePath: { eq: "גמד-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    monster: file(relativePath: { eq: "מפלצת-מפוסלת-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    pooh: file(relativePath: { eq: "פו-הדב-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    crocodile: file(relativePath: { eq: "תנין-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    turtle: file(relativePath: { eq: "צב-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bunny: file(relativePath: { eq: "ארנבת-מפוסלת-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    spottedBear: file(relativePath: { eq: "דובי-מנומר-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    duck: file(relativePath: { eq: "ברווז-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    grouchySmurf: file(relativePath: { eq: "רגזני-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    eeyore: file(relativePath: { eq: "איה-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    ostrich: file(relativePath: { eq: "יען-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    faces: file(relativePath: { eq: "פרצופים-מפוסלים-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    timon: file(relativePath: { eq: "טימון-דו-מימדי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    lumiere: file(relativePath: { eq: "לומייר-מפוסל-בבצק-סוכר2.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    cookiemonster: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגיות-שוקולד-צ׳יפס-מושלמות-ועוגיפלצת-מבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    cups: file(
      relativePath: {
        eq: "עוגת-ממתקים-ומפלצות-מפוסלות-בבצק-סוכר-על-ערימת-ספלים.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bambi: file(
      relativePath: {
        eq: "במבי-עומד-בעל-מבנה-של-4-רגליים-מפוסל-בבצק-סוכר-בעבודת-יד.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    horse: file(relativePath: { eq: "סוס-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    surfer: file(
      relativePath: { eq: "גולש-הומוריסטי-על-גלשן-מפוסל-בבצק-סוכר.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    farmer: file(relativePath: { eq: "חקלאי-הומוריסטי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    flamingo: file(
      relativePath: { eq: "עוגת-קומות-של-פלמינגו-מעוצבת-בבצק-סוכר.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    flamingo2: file(relativePath: { eq: "פלמינגו-גדול-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    waiter: file(relativePath: { eq: "מלצר-הומוריסטי-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    dinosaur: file(relativePath: { eq: "דינוזאורית-מפוסלת-בבצק-סוכר.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    heroes: file(
      relativePath: {
        eq: "עוגת-גיבורי-על-תלת-מימד-עם-קומות-באוויר-מפוסלת-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    indianCake: file(
      relativePath: {
        eq: "עוגה-מצוירת-ונערה-הודית-מפוסלת-בבצק-סוכר-ושמש-מאחוריה.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    amsterdamCake: file(
      relativePath: {
        eq: "עוגת-אמסטרדם-מצוירת-וצלמת-מפוסלת-בבצק-סוכר-עם-אופניים-ומטריה.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    indianGirl: file(
      relativePath: { eq: "נערה-הודית-מפוסלת-בבצק-סוכר-עם-שמש-מאחוריה.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    giantCat: file(
      relativePath: { eq: "עוגה-מעוצבת-בבצק-סוכר-בצורת-חתול-ענק.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    kitchen: file(relativePath: { eq: "עוגת-שמח-במטבח-מפוסלת-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    playingKids: file(
      relativePath: { eq: "ילדים-במטבח-מפוסלים-בבצק-סוכר.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    begginers: file(
      relativePath: {
        eq: "אריה-דובי-ארנב-דינוזאור-ואפרוח-מפוסלים-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`
